import { Avatar, Typography, Stack } from '@mui/material';
import { darkTextColor, GetImageFromUrl } from '../../constants';
import moment from 'moment';
import { NotificationNumber } from '../../components/notificationNumber';

const trimText = (text) => {
  if (text?.length > 75) {
    return `${text.slice(0, 75)}...`;
  }
  return text;
};

const DoctorCard = ({ docData, selected, index, updateSelected }) => {
  const { user, chat, lastMessageTime } = docData;
  const avatar = GetImageFromUrl(user?.profile_picture_url);
  const specialty = user.specialties.find((spec) => spec.class === 'main');
  const fromNow = lastMessageTime
    ? moment(moment(lastMessageTime).toDate()).fromNow()
    : '';

  return (
    <>
      <Stack
        direction="column"
        key={lastMessageTime || index}
        gap={1}
        sx={{
          minHeight: '120px',
          p: 2,
          width: '100%',
          cursor: 'pointer',
          bgcolor: user.id === selected?.id && '#F7F7F7',
          borderRight: user.id === selected?.id && '2px solid #47BB92',
          px: 3,
        }}
        onClick={() => updateSelected(user.id, docData)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1}>
            <Avatar
              sx={{
                width: '45px',
                height: '50px',
                borderRadius: '4px',
              }}
              src={avatar}
              variant="square"
            >
              {user.firstName?.charAt(0)}
            </Avatar>
            <Stack direction="column">
              <Typography
                variant="body1"
                fontWeight={600}
                color={darkTextColor}
                sx={{ maxWidth: '170px' }}
              >
                {user.firstName}&nbsp;{user.lastName}
              </Typography>
              <Typography
                color={darkTextColor}
                variant="body2"
                sx={{ opacity: '0.75' }}
              >
                {specialty?.specialty}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            variant="body2"
            color={darkTextColor}
            sx={{ opacity: 0.75 }}
          >
            {fromNow}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            color={darkTextColor}
            sx={{ opacity: 0.75, overflowWrap: 'anywhere' }}
          >
            {chat[chat.length - 1]?.isFile
              ? 'Attachment'
              : trimText(chat[chat.length - 1]?.content) || ''}
          </Typography>
          <NotificationNumber
            number={
              chat?.filter(
                (ch) => ch.readAt === null && ch.senderId === user.id,
              )?.length || 0
            }
          />
        </Stack>
      </Stack>
      {user.id !== selected?.id ? (
        <div
          style={{
            width: '90%',
            borderRadius: '4px',
            alignSelf: 'center',
            height: '1px',
            backgroundColor: '#F7F7F7',
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DoctorCard;
