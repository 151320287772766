import { AUTH_ROUTE } from '../constants';
import configuration from '../config';

const post = async (email, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('password', password);
  urlencoded.append('email', email);

  const response = await fetch(`${configuration.api.url}/${AUTH_ROUTE}`, {
    headers: {
      'Max-Forwards': '0',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    },
    method: 'POST',
    body: urlencoded,
  });

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const get = async () => {
  const response = await fetch(`${configuration.api.url}/${AUTH_ROUTE}`, {
    headers: {
      'content-type': 'application/json',
      'Max-Forwards': '0',
    },
    method: 'GET',
  });

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getRefresh = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${AUTH_ROUTE}/refresh-token`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const logout = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${AUTH_ROUTE}/logout`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getUser = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${AUTH_ROUTE}/get-user`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verifyToken = async (email, password, token) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('password', password);
  urlencoded.append('email', email);
  urlencoded.append('token', token);

  const response = await fetch(`${configuration.api.url}/${AUTH_ROUTE}/token`, {
    method: 'POST',
    body: urlencoded,
    headers: {
      'Max-Forwards': '0',
    },
  });

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Auth = { post, get, getUser, logout, getRefresh, verifyToken };

export default Auth;
