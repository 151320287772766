import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Typography,
  Grid,
  Tooltip,
} from '@mui/material';
import React, { useState, useContext, useRef, useEffect } from 'react';
import Navbar from '../../components/navbar';
import UserContext from '../../contexts/user/context';
import {
  darkTextColor,
  decryptNumber,
  encryptNumber,
  GetImageFromUrl,
  MESSAGES_FOOTER_SIZE,
  RESPONSE_STATUS,
} from '../../constants';
import { Stack } from '@mui/system';
import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '../../Icons/serachIcon';
import DoctorCard from './doctorCard';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBody from './messageBody';
import Messages from '../../services/messages';
import CloseLight from '../../Icons/closeLight';
import Users from '../../services/users';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import AttachmentIcon from '../../Icons/attachmentIcon';
import { FileUploader } from 'react-drag-drop-files';
import { LoadingButton } from '@mui/lab';
import AlertDialog from '../../components/alertDialog';
import AddIcon from '@mui/icons-material/Add';
import NewMessage from './newMessage';
import moment from 'moment';
import { EndorseButton } from '../../components/endorseButton';
import Feedback from '../../components/feedback';
import FlagIcon from '@mui/icons-material/Flag';
import { PatientNameCard } from './patientNameCard';
import { FooterVerifiedUser } from '../../components/footer';

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
const fileTypes = ['JPEG', 'PNG', 'GIF', 'PDF', 'JPG'];

const MessagesContent = () => {
  const {
    user,
    token,
    chats,
    setChats,
    messageChannels,
    setMessageChannels,
    chatLoading,
    socket,
  } = useContext(UserContext);
  const [currentMessage, setCurrentMessage] = useState('');
  const containerRef = useRef();
  const [selected, setSelected] = useState();
  const [docData, setDocData] = useState();
  const [searchName, setSearchName] = useState('');
  const navigate = useNavigate();
  const { search } = useLocation();
  const [showChannels, setShowChannels] = useState(true);
  const query = new URLSearchParams(search);
  const encryptedId = query.get('doctorId');
  const doctorId = encryptedId ? decryptNumber(parseInt(encryptedId)) : null;
  const [loading, setLoading] = useState(!!doctorId);
  const getUserRef = useRef();
  const [allChats, setAllChats] = useState([]);

  const [files, setFiles] = useState([]);
  const [tempoChats, setTempoChats] = useState([]);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [heightCards, setHeightCards] = useState();
  const cardsContainerRef = useRef();

  const [messgaeLoading, setMessageLoading] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);

  const [isFeedback, setIsFeedback] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  const handleChange = (newFile) => {
    if (newFile && newFile != undefined) {
      let newFiles = [];

      Array.from(newFile).forEach((file) => {
        newFiles.push(file);
      });
      const largeFiles = newFiles.find((file) => file.size > 5000000);
      if (largeFiles) {
        setErrorMessage('File size should be less than 5MB');
        setShowError(true);
        return;
      }
      newFiles = newFiles.filter((file) => file.size < 5000000);

      newFiles = newFiles.filter((file, i) => i < 2);

      setFiles([...newFiles]);
    } else {
      console.log('file error');
    }
  };

  const updateHeight = () => {
    if (cardsContainerRef.current) {
      const cardContainerHeight =
        window.innerHeight -
        cardsContainerRef.current.getBoundingClientRect().top -
        5 -
        MESSAGES_FOOTER_SIZE;
      setHeightCards(cardContainerHeight);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (cardsContainerRef.current) {
      const getEle = document.getElementById('cards-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight -
          getEle.getBoundingClientRect().top -
          20 -
          MESSAGES_FOOTER_SIZE;
        setHeightCards(elementHeight);
      }
    }
  }, [containerRef, user, cardsContainerRef.current]);

  const scrollChatToBottom = () => {
    setTimeout(() => {
      const getEle = document.getElementById('scroll-container');
      if (getEle) {
        getEle.scrollTop = getEle.scrollHeight;
      }
    }, 250);
  };
  useEffect(() => {
    scrollChatToBottom();
  }, [allChats]);

  useEffect(() => {
    updateHeight();
    scrollChatToBottom();
  }, [selected, allChats, selectedPatient]);

  const handleChatResponse = (response) => {
    setMessageChannels(response.channels);

    const chatsArr = [];
    const tempMessages = response.body;
    tempMessages.forEach((parent) => {
      let reciverId =
        parent.receiverId === user.id ? parent.senderId : parent.receiverId;

      const isAlready = chatsArr.find((arr) =>
        arr.find((x) => x.senderId === reciverId || x.receiverId === reciverId),
      );
      if (!isAlready) {
        const getChatWithUser = tempMessages.filter(
          (child) =>
            child.senderId === reciverId || child.receiverId === reciverId,
        );
        chatsArr.push(
          getChatWithUser.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
          ),
          // user: currentUser,
        );
      }
    });
    const addUserToChatArr = chatsArr
      .map((chat) => {
        const reciverId =
          chat[0]?.receiverId === user.id
            ? chat[0].senderId
            : chat[0].receiverId;
        const currentUser = response.users.find((use) => use.id === reciverId);
        return {
          user: currentUser,
          chat: chat,
          lastMessageTime: chat[chat.length - 1]?.createdAt,
        };
      })
      ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    setChats(addUserToChatArr.filter((chat) => chat?.user));
  };

  const handleMarkAsUnread = async (id) => {
    try {
      const response = await Messages.unread(token, id);
      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        handleChatResponse(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const readAllChat = async (id) => {
    if (!selectedPatient) {
      const openChat = chats.find((chat) => chat.user.id === id);
      if (openChat) {
        const isUnread = openChat?.chat?.filter(
          (ch) =>
            ch.readAt === null &&
            ch.senderId !== user.id &&
            ch.patientId === null,
        )?.length;
        if (!isUnread) {
          return;
        }
      }
    }

    if (selectedPatient) {
      const openChat = chats.find((chat) => chat.user.id === id);
      if (openChat) {
        const isUnread = openChat?.chat?.filter(
          (ch) =>
            ch.readAt === null &&
            ch.senderId !== user.id &&
            ch.patientId === selectedPatient,
        )?.length;
        if (!isUnread) {
          return;
        }
      }
    }
    try {
      const response = await Messages.readAll(token, id, selectedPatient);
      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        handleChatResponse(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateSelected = async (id, docData) => {
    setSelected({ id, data: docData });
    setCurrentMessage('');
    updateHeight();
    scrollChatToBottom();
    if (docData?.temp) {
      return;
    }
    readAllChat(id);
  };

  const getUser = async (userId) => {
    if (userId) {
      const checkUserChatExists = allChats.find(
        (chat) => chat?.user?.id === userId,
      );
      if (checkUserChatExists) {
        return;
      }
    }
    try {
      const response = await Users.getById(token, userId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        const tempChat = {
          user: response.body,
          chat: [],
          lastMessageTime: null,
          temp: true,
        };
        setTempoChats([tempChat]);
        setSelected({ id: response.body.id, data: tempChat });
        // setDocData(response.body);
      }
    } catch (e) {
      console.log(e.message);
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (doctorId && loading && user) {
      const getChat = allChats.find(
        (body) => body.user.id === Number(doctorId),
      );
      if (getChat) {
        clearTimeout(getUserRef.current);
        updateSelected(getChat.user.id, getChat);
        setLoading(false);
      } else {
        getUserRef.current = setTimeout(() => {
          getUser(doctorId);
        }, [2000]);
      }
    }

    return () => {
      clearTimeout(getUserRef.current);
    };
  }, [doctorId, allChats, user]);

  useEffect(() => {
    if (!doctorId && chats.length) {
      const sortChats = chats.sort(
        (a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime),
      );
      if (sortChats[0] && sortChats?.length) {
        setSelected({ id: sortChats[0]?.user?.id, data: sortChats[0] });
      }
    }
  }, [doctorId]);
  const updateReadAll = () => {
    const getChats = allChats.find(
      ({ chat, user }) =>
        user.id === selected.id && chat[chat.length - 1].readAt === null,
    );
    if (getChats) {
      readAllChat(selected.id);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (currentMessage.trim() !== '' || files?.length) {
      setMessageLoading(true);

      try {
        if (files.length) {
          const response = await Messages.send(
            token,
            selected.id,
            currentMessage,
            files,
            selectedPatient,
          );
          if (response.status === RESPONSE_STATUS.error) {
            // setErrorMessage(response.message);
            return;
          }
          if (response.status === RESPONSE_STATUS.success) {
            handleChatResponse(response);
            setCurrentMessage('');
            setFiles([]);
          }
        } else {
          socket.emit('recmessage', {
            message: currentMessage,
            to: selected.id,
            patient: selectedPatient || null,
            userId: user.id,
          });
          setCurrentMessage('');
        }

        return;
      } catch {
        console.log(e);
      } finally {
        setMessageLoading(false);
      }
    }
  };
  useEffect(() => {
    if (selected?.data?.temp) {
      setDocData(tempoChats.find(({ user }) => user.id === selected?.id));
      return;
    }
    if (allChats.length) {
      setDocData(allChats.find(({ user }) => user.id === selected?.id));
    }
  }, [allChats, selected]);

  useEffect(() => {
    if (chats) {
      if (tempoChats.length) {
        const tempId = tempoChats[0].user.id;
        if (tempId) {
          const findTempInChats = chats.find(
            (chat) => chat?.user?.id === tempId,
          );
          if (findTempInChats) {
            setTempoChats([]);
            setSelected({ id: tempId, data: findTempInChats });
            // setAllChats(chats);
            // return
          }
        }
      }
      setAllChats(chats.filter(({ user }) => user));
    }
  }, [chats]);

  const handleKeyDown = (event) => {
    if (event.which === 13) {
      sendMessage(event);
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (selected) {
      readAllChat(selected.id);
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (selected) {
      const isDirectUnread = selected.data.chat.find(
        (cha) => !cha.readAt && !cha.patientId,
      );
      if (isDirectUnread) {
        setSelectedPatient(null);
        return;
      }
      const isUnreadChannel = selected.data.chat.find(
        (cha) => !cha.readAt && cha.patientId,
      );
      if (isUnreadChannel) {
        setSelectedPatient(isUnreadChannel.patientId);
        return;
      }
      setSelectedPatient(null);
    }
  }, [selected]);

  if (loading) {
    return <LoadingCircularProgress />;
  }

  const selectedChannel = selectedPatient
    ? messageChannels?.find((ref) => ref.id === selectedPatient)
    : null;
  const avatar = GetImageFromUrl(docData?.user?.profile_picture_url);

  const userChats = [
    ...tempoChats,
    ...allChats.sort(
      (a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime),
    ),
  ].filter(
    ({ user }) =>
      user.firstName?.toLowerCase().includes(searchName.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(searchName.toLowerCase()) ||
      `${user.firstName?.toLowerCase()}${user.lastName?.toLowerCase()}`.includes(
        searchName.replaceAll(' ', '').toLowerCase(),
      ),
  );

  const filteredChat =
    messageChannels?.filter(
      (ref) =>
        (ref.doctorId === user?.id && ref.referredToId === selected?.id) ||
        (ref.doctorId === selected?.id && ref.referredToId === user?.id),
    ) || null;

  return (
    <>
      <Feedback openDialog={isFeedback} setOpenDialog={setIsFeedback} />
      <NewMessage
        title="New Message"
        open={newMessage}
        setOpen={() => setNewMessage(false)}
        getUser={getUser}
        allChats={allChats}
        updateSelected={updateSelected}
      />
      <AlertDialog
        error
        title="Error"
        open={showError}
        setOpen={() => setShowError(false)}
        message={errorMessage}
      />
      <Box
        sx={{
          // maxHeight: '100vh',
          maxHeight: `calc(100vh - ${MESSAGES_FOOTER_SIZE}px)`,
          // height: '100vh',
          height: `calc(100vh - ${MESSAGES_FOOTER_SIZE}px)`,

          overflow: 'hidden',
        }}
      >
        <Navbar />
        {chatLoading ? (
          <LoadingCircularProgress />
        ) : (
          <Stack direction="row" sx={{ width: '100vw' }}>
            <Box
              sx={{
                width: '30%',
                minWidth: '350px',
                bgcolor: '#FFFFFF',
                height: '100vh',
                p: 3,
                px: 0,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ pl: 3, pr: 1.5 }}
              >
                <Typography variant="h5" color={darkTextColor} fontSize="18px">
                  Messages
                </Typography>
                <IconButton color="primary" onClick={() => setNewMessage(true)}>
                  <AddIcon />
                  <Typography>New</Typography>
                </IconButton>
              </Stack>
              <CustomInputField
                value={searchName}
                width="100%"
                height="30"
                border="1px solid #E2E2E2"
                placeholder="Search messages"
                borderRadius="4px"
                sx={{
                  my: 1,
                  mb: 2.5,
                  px: 3,
                  width: '100%',
                  position: 'relative',
                  '& .MuiInputBase-input': {
                    pl: '50px',
                  },
                }}
                onChange={(e) => setSearchName(e.target.value)}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      left: 30,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Stack
                id="cards-container"
                ref={cardsContainerRef}
                sx={{
                  overflowY: 'auto',
                  maxHeight: `${heightCards ? `${heightCards}px` : '100%'}`,
                  marginRight: '0px',
                  width: '100%',
                }}
              >
                {userChats?.length ? (
                  userChats.map((doc, i) => (
                    <React.Fragment key={i}>
                      <DoctorCard
                        index={i}
                        updateSelected={updateSelected}
                        docData={doc}
                        setSelected={setSelected}
                        selected={selected}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <Stack alignItems="center" sx={{ marginTop: '20%' }}>
                    <Typography variant="h6" fontWeight={400} color="#212634">
                      No messages
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
            {docData ? (
              <Stack sx={{ width: '70%' }}>
                <Card sx={{ p: 4, borderRadius: '0px', mx: '2px' }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Stack direction="row" gap={2} alignItems="center">
                        <Avatar
                          sx={{
                            width: '75px',
                            height: '80px',
                            borderRadius: '4px',
                          }}
                          src={avatar}
                          variant="square"
                        >
                          {docData?.user.firstName}
                        </Avatar>
                        <Stack direction="column">
                          <Typography
                            variant="h5"
                            fontWeight={600}
                            color={darkTextColor}
                          >
                            {docData?.user.firstName}&nbsp;
                            {docData?.user.lastName}
                          </Typography>
                          <Typography
                            color={darkTextColor}
                            variant="body2"
                            sx={{ opacity: '0.75' }}
                          >
                            {
                              docData.user.specialties.find(
                                (spec) => spec.class === 'main',
                              )?.specialty
                            }
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        gap={{ md: 2, sm: 1, xs: 1 }}
                        justifyContent="flex-end"
                      >
                        <EndorseButton
                          doctorId={docData?.user?.id}
                          totalLikes={docData?.user?.favoritesOff?.length || 0}
                          doctorEndorsements={docData?.user.favoritesOff}
                        />
                        <Tooltip title="Flag user">
                          <Button
                            aria-label="flag"
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsFeedback(true);
                            }}
                            sx={{
                              borderRadius: 0,
                              border: `1px solid #E2E2E2`,
                              borderRadius: '4px',
                              color: '#000000',
                              '& .MuiButton-startIcon': { margin: 0 },
                              '&:hover': {
                                border: `1px solid #E2E2E2`,
                                color: darkTextColor,
                                bgcolor: 'transparent',
                              },
                            }}
                            startIcon={<FlagIcon />}
                          />
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <Button
                      color="primary"
                      size="large"
                      className="custom-button"
                      onClick={() => {
                        const profileNumber = encryptNumber(selected?.id);
                        navigate(`/doctor/${profileNumber}`);
                      }}
                      variant="contained"
                      fullWidth
                      sx={{
                        height: 40,
                        width: { md: '10rem', sm: '9rem' },
                        border: `1px solid #E2E2E2`,
                        borderRadius: '4px',
                        color: '#000000',
                        bgcolor: 'transparent',

                        '& .MuiButton-startIcon': { margin: 0 },
                        '&:hover': {
                          border: `1px solid #E2E2E2`,
                          color: darkTextColor,
                          bgcolor: 'transparent',
                        },
                      }}
                    >
                      View profile
                    </Button>
                  </Stack>
                </Card>
                <Stack
                  direction="row"
                  sx={{ width: { md: 'auto', sm: '93%', xs: '93%' } }}
                >
                  <Stack sx={{ width: '100%' }}>
                    <Card sx={{ p: 2, m: '1px', borderRadius: '0px', px: 3 }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {selectedChannel ? (
                          <Stack
                            direction="column"
                            onClick={() => {
                              const profileNumber = encryptNumber(
                                selectedChannel.id,
                              );
                              navigate(`/patient/${profileNumber}`);
                            }}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Typography
                              fontWeight={600}
                              color={darkTextColor}
                              fontSize="14px"
                            >
                              {selectedChannel.firstname}&nbsp;
                              {selectedChannel.lastname}
                              ,&nbsp;
                              {getAge(selectedChannel?.dateOfBirth)} y.o.
                            </Typography>
                            <Typography
                              color={darkTextColor}
                              fontSize="12px"
                              sx={{ opacity: 0.75 }}
                            >
                              Referred on{' '}
                              {moment(selectedChannel.createdAt).format(
                                'D-MMM',
                              )}
                            </Typography>
                          </Stack>
                        ) : (
                          <Typography
                            fontWeight={600}
                            color={darkTextColor}
                            sx={{ opacity: 0.56 }}
                          >
                            Direct Message
                          </Typography>
                        )}
                        <Typography
                          onClick={() => setShowChannels(!showChannels)}
                          color={darkTextColor}
                          sx={{
                            opacity: 0.56,
                            cursor: 'pointer',
                            display: { md: 'block', sm: 'none' },
                          }}
                        >
                          {showChannels ? 'Hide Channels' : 'Show Channels'}
                        </Typography>
                      </Stack>
                    </Card>

                    {selected ? (
                      <MessageBody
                        onClick={() => updateReadAll()}
                        handleMarkAsUnread={handleMarkAsUnread}
                        user={user}
                        chats={[...tempoChats, ...allChats].find(
                          (cha) => cha.user.id === selected.data.user.id,
                        )}
                        sender={selected.data.user}
                        seleted={selected}
                        selectedPatient={selectedPatient}
                      />
                    ) : (
                      <></>
                    )}
                    <form
                      onSubmit={(e) => sendMessage(e)}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '60px',
                        background: '#FFFFFF',
                        margin: '2px',
                      }}
                    >
                      <CustomInputField
                        value={files?.length ? '' : currentMessage}
                        sx={{
                          width: 'calc(100% - 4px)',
                          '& .MuiInputBase-input': {
                            height: '16px !important',
                            mr: '180px',
                            pr: '180px',
                            p: 3,
                            fontSize: '14px',
                          },
                        }}
                        onFocus={() => updateReadAll()}
                        placeholder="Type your message"
                        rows={1}
                        multiline
                        width="100%"
                        disabled={files?.length}
                        onKeyDown={handleKeyDown}
                        inputProps={{
                          maxLength: 255,
                        }}
                        onChange={(e) => {
                          if (e.target.value.length < 250)
                            setCurrentMessage(e.target.value);
                        }}
                        endAdornment={
                          <>
                            {files?.length ? (
                              <Grid
                                direction="row"
                                sx={{
                                  position: 'absolute',
                                  left: '20px',
                                  flexWrap: 'nowrap',
                                  overflowX: 'scroll',
                                  width: '75%',
                                }}
                                container
                                gap={1}
                              >
                                {files.map((file, i) => (
                                  <Card
                                    sx={{ bgcolor: '#DCDCDC', px: 1 }}
                                    key={i}
                                  >
                                    <Stack
                                      sx={{ flexWrap: 'nowrap' }}
                                      direction="row"
                                    >
                                      <Typography color={darkTextColor}>
                                        {file.name}
                                      </Typography>
                                      <IconButton
                                        onClick={() =>
                                          setFiles(
                                            files.filter(
                                              (f) => f.name !== file.name,
                                            ),
                                          )
                                        }
                                      >
                                        <CloseLight />
                                      </IconButton>
                                    </Stack>
                                  </Card>
                                ))}
                              </Grid>
                            ) : (
                              <></>
                            )}
                            <InputAdornment
                              position="end"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'absolute',
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              <FileUploader
                                multiple={true}
                                handleChange={handleChange}
                                name="file"
                                children={
                                  <IconButton sx={{ margin: '10px' }}>
                                    <AttachmentIcon />
                                  </IconButton>
                                }
                                types={fileTypes}
                              />

                              <LoadingButton
                                type="submit"
                                endIcon={<></>}
                                variant="contained"
                                loading={messgaeLoading}
                                loadingPosition="end"
                                sx={{
                                  backgroundColor: '#1665D8',
                                  width: '8rem',
                                  height: '40px',
                                  color: '#FFFFFF',

                                  '&:hover': {
                                    backgroundColor: '#1665D8',
                                    color: '#FFFFFF',
                                  },
                                  '& 	.MuiLoadingButton-loading': {
                                    color: 'black',
                                  },
                                }}
                              >
                                Send
                              </LoadingButton>
                            </InputAdornment>
                          </>
                        }
                      />
                    </form>
                  </Stack>
                  <Box
                    sx={{
                      display: { md: 'block', sm: 'none' },
                      backgroundColor: '#FFFFFF',
                    }}
                  >
                    {showChannels && (
                      <div
                        style={{
                          width: '280px',
                          margin: '1px',
                          marginRight: '0px',
                          transition: '3s',
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        <Card
                          sx={{
                            p: 2,
                            m: '1px',
                            borderRadius: '0px',
                            px: 3,
                          }}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography fontWeight={500} color={darkTextColor}>
                              {filteredChat?.length} Channels
                            </Typography>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowChannels(!showChannels)}
                            >
                              <CloseLight />
                            </div>
                          </Stack>
                        </Card>
                        <Card
                          sx={{
                            p: 2,
                            m: '1px',
                            mb: 1,
                            borderRadius: '0px',
                            px: 3,
                            bgcolor: !selectedPatient
                              ? 'rgba(71,187,146, 0.14)'
                              : '#FFFFFF',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedPatient(null)}
                        >
                          <Typography fontWeight={500} color={darkTextColor}>
                            Direct Message
                          </Typography>
                        </Card>
                        {messageChannels.find(
                          (ref) =>
                            (ref.doctorId === user.id &&
                              ref.referredToId === selected.id) ||
                            (ref.doctorId === selected.id &&
                              ref.referredToId === user.id),
                        ) ? (
                          <Typography
                            fontWeight={600}
                            sx={{ px: 3 }}
                            variant
                            color={darkTextColor}
                          >
                            Patient channels
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Box
                          sx={{
                            overflowY: 'auto',
                            height: 'calc(41em - 45px)',
                          }}
                        >
                          {selected &&
                            messageChannels
                              ?.filter(
                                (ref) =>
                                  (ref.doctorId === user?.id &&
                                    ref.referredToId === selected?.id) ||
                                  (ref.doctorId === selected?.id &&
                                    ref.referredToId === user?.id),
                              )
                              .map((ref, id) => {
                                const currentChat = userChats.find(
                                  (chat) => chat.user.id === selected.id,
                                );
                                if (!currentChat) {
                                  return <></>;
                                }
                                return (
                                  <React.Fragment key={id}>
                                    <PatientNameCard
                                      user={user}
                                      referral={ref}
                                      chats={currentChat}
                                      selectedPatient={selectedPatient}
                                      setSelectedPatient={setSelectedPatient}
                                    />
                                  </React.Fragment>
                                );
                              })}
                        </Box>
                      </div>
                    )}
                  </Box>
                </Stack>
              </Stack>
            ) : (
              <Stack sx={{ width: '70%' }}>
                <Card sx={{ p: 2, borderRadius: '0px', mx: '2px' }}>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    color={darkTextColor}
                  >
                    New Message
                  </Typography>
                </Card>
              </Stack>
            )}
            <FooterVerifiedUser showText />
          </Stack>
        )}
      </Box>
    </>
  );
};

const CustomInputField = styled((props) => <InputBase {...props} />)(
  ({ theme, width, height, border, borderRadius }) => ({
    'label + &': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
      borderRadius: borderRadius,
      height: height && `${height}px`,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: border,
      fontSize: 16,
      marginTop: '5px',
      width: `${width ? width : 'auto'}`,
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      color: 'black',
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        // border: '2px solid #47BB92',
        // borderColor: theme.palette.primary.main,
      },
    },
  }),
);
const MessagesComponent = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    return <LoadingCircularProgress />;
  }
  return <MessagesContent />;
};

export default MessagesComponent;
