import { useEffect, useState, useContext, useRef } from 'react';
import Navbar from '../../components/navbar';
import { Box, Card, Typography, Stack } from '@mui/material';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import ReferralTable from './referralTable';
import Patients from '../../services/patients';
import UserContext from '../../contexts/user/context';
import HistoryTable from './historyTable';
import moment from 'moment';
import ReviewPatientReferral from '../Home/reviewPatientReferral';
import FrowardsReferal from '../Home/reviewPatientReferral/forwardReferral';
import Insights from './insights';
import CancelDialog from '../../components/cancelDialog';
import { LoadingCircularProgressSized } from '../../components/loadingCircularProgress';
import { FooterVerifiedUser } from '../../components/footer';

const Referrals = () => {
  const [receivedReferrals, setReceivedReferrals] = useState([]);
  const [sentReferrals, setSentReferrals] = useState([]);
  const [data, setData] = useState([]);
  const [responseTime, setResponseTime] = useState({ time: 0, text: 'Days' });
  const [selectedReferral, setSelectedReferral] = useState();
  const [referralStatus, setReferralStatus] = useState(false);
  const [openReviewPatient, setOpenReviewPatient] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [referralLoading, setReferralLoading] = useState(false);

  const [historyLoading, setHistoryLoading] = useState(true);

  const idRef = useRef();
  const {
    token,
    user,
    allReferrals,
    setAllReferrals,
    setAcceptReferralDialogue,
    siteReferralLoading,
  } = useContext(UserContext);

  const updateReferrals = (body) => {
    try {
      const tempReceived = body
        .filter((ref) => ref.referredToId === user.id && !ref.status)
        .sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
        .reverse();

      const _tempSent = body
        .filter(
          (ref) =>
            (ref.doctorId === user.id || ref.referralFrom === user.id) &&
            !ref.status,
        )
        .sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
        .reverse();
      const tempSent = _tempSent?.reduce((acc, cur) => {
        if (!acc[cur.id]) {
          acc[cur.id] = cur;
        }
        return acc;
      }, {});

      setSentReferrals(Object.values(tempSent));
      setReceivedReferrals(tempReceived);
    } catch {
      console.log('error getting referrals');
    } finally {
      setReferralLoading(false);
    }
  };

  const getPastReferrals = async () => {
    if (token) {
      try {
        const response = await Patients.getPastRef(token);

        if (response.status === RESPONSE_STATUS.error) {
          // setErrorMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setData(response?.body);
          return;
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        if (historyLoading) {
          setHistoryLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    updateReferrals(allReferrals);
    getPastReferrals();
  }, [allReferrals]);

  useEffect(() => {
    getPastReferrals();
  }, [token]);
  useEffect(() => {
    if (user && data) {
      const _tempData = data?.filter(
        (patient) => patient.status && patient.referredToId === user.id,
      );
      if (_tempData.length) {
        let totalHouse = 0;
        _tempData.forEach((patient) => {
          var a = moment(patient.updatedAt);
          var b = moment(patient.createdAt);
          totalHouse += a.diff(b, 'hours');
        });
        let getData = moment
          .duration(totalHouse / _tempData.length, 'hours')
          .humanize();

        if (getData === 'a few seconds') {
        }
        setResponseTime({
          time: getData.split(' ')[0],
          text: getData.split(' ').slice(1).join(' '),
        });
      }
      setResponseTime({ time: 0, text: 'days' });
    }
  }, [data, user]);

  if (!user) {
    return <></>;
  }

  const handleDrawer = (referralData, status) => {
    setSelectedReferral(referralData);
    setReferralStatus(status);
    setOpenReviewPatient(true);
  };

  const handleOpenCancelDialog = (id) => {
    idRef.current = id;
    setCancel(true);
  };

  const handleCancelReferral = async () => {
    try {
      const response = await Patients.del(token, idRef.current);

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        setData(response?.body);
        setReferralLoading(true);
        updateReferrals(response?.body);
        setAllReferrals(response?.body);
        setCancel();
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    }
  };

  const updateStatus = async (
    status,
    reasonToDecline = '',
    additionalComments = '',
    referredTo = null,
    doctorName = '',
    selectedStaff,
    updatedStaff,
  ) => {
    try {
      setReferralLoading(true);
      const response = await Patients.put(
        token,
        selectedReferral.id,
        status,
        reasonToDecline,
        additionalComments,
        referredTo,
        updatedStaff,
      );

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        const filteredData = allReferrals?.filter(
          (ref) => ref.id !== response?.patient.id,
        );
        setReferralLoading(true);
        updateReferrals(filteredData);
        setAllReferrals(filteredData);

        setAcceptReferralDialogue(false);
        setIsForward(false);
        setOpenReviewPatient(false);
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    } finally {
      setReferralLoading(false);
    }
  };

  return (
    <>
      <CancelDialog
        open={cancel}
        setOpen={setCancel}
        handleCancel={handleCancelReferral}
        id={1}
      />
      <ReviewPatientReferral
        anchor={openReviewPatient}
        setAnchor={setOpenReviewPatient}
        referralData={selectedReferral}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />

      <FrowardsReferal
        anchor={isForward}
        setAnchor={setIsForward}
        referralData={selectedReferral}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        isForward={isForward}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />
      <Stack sx={{ position: 'fixed', width: '100%', top: 0, zIndex: 100 }}>
        <Navbar />
      </Stack>
      <Box
        sx={{
          maxHeight: '100vh',
          height: '100vh',
          mt: { md: '7vh', sm: '70px', xs: '70px' },
        }}
      >
        <Box sx={{ p: 5, mb: -2 }}>
          <Card sx={{ p: 2 }}>
            <Typography color={darkTextColor} gutterBottom variant="h5">
              Pending actions
            </Typography>
            {siteReferralLoading ? (
              <LoadingCircularProgressSized />
            ) : (
              <>
                {receivedReferrals && receivedReferrals.length ? (
                  <ReferralTable
                    heading="Received"
                    tableData={receivedReferrals}
                    handleDrawer={handleDrawer}
                    user={user}
                    loading={siteReferralLoading}
                  />
                ) : (
                  <></>
                )}
                {sentReferrals && sentReferrals.length ? (
                  <ReferralTable
                    heading="Sent"
                    tableData={sentReferrals?.reverse()}
                    handleCancelReferral={handleOpenCancelDialog}
                    user={user}
                    loading={siteReferralLoading}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            {/* <ReferralTable heading="Sent" tableData={sentReferrals} /> */}
          </Card>
        </Box>
        <Insights data={data} />
        <Box sx={{ p: 5, my: -4 }}>
          <Card sx={{ p: 2 }}>
            {historyLoading ? (
              <LoadingCircularProgressSized />
            ) : (
              <HistoryTable heading="History" data={data} user={user} />
            )}
          </Card>
        </Box>
        <FooterVerifiedUser />
      </Box>
    </>
  );
};

export default Referrals;
