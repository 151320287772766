import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Card,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DefaultImage from '../../../Icons/defaultImage';
import TooltipIcon from '../../../Icons/tooltipIcon';
import AlertDialog from '../../../components/alertDialog';
import { CapitalizeFirstLetter, darkTextColor } from '../../../constants';
import { Box } from '@mui/system';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';

const CreateAccount = (props) => {
  const {
    formik,
    needsValidation,
    selectedImage,
    setSelectedImage,
    errMessage,
    setErrMessage,
    isDrawerOpen,
  } = props;
  const [values, setValues] = useState({
    showPassword: false,
    showRepeatPassword: false,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const [imageErr, setImageErr] = useState();
  const [showPasswordReqs, setShowPasswordReqs] = useState(true);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowRepeatPassword = () => {
    setValues({
      ...values,
      showRepeatPassword: !values.showRepeatPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (errMessage) {
      setOpenDialog(true);
    }
  }, [errMessage, setOpenDialog]);

  useEffect(() => {
    if (!openDialog) {
      setErrMessage();
    }
  }, [openDialog, setErrMessage]);

  useEffect(() => {
    if (formik.values.password && !formik.errors.password) {
      setShowPasswordReqs(false);
    } else {
      setShowPasswordReqs(true);
    }
  }, [formik]);

  return (
    <>
      {Boolean(errMessage) ? (
        <AlertDialog
          title="Error"
          open={openDialog}
          setOpen={setOpenDialog}
          message={CapitalizeFirstLetter(errMessage)}
          error
        />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="column"
        alignItems="start"
        sx={{
          alignSelf: 'start',
          ml: isDrawerOpen ? 40 : 0,
          px: 1,
          width: '80vw',
          maxWidth: 950,
        }}
      >
        <Typography color={darkTextColor} gutterBottom variant="h5">
          Create Account
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Grid
            maxWidth={true}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            gap={2}
            sx={{
              bgcolor: '#FFFFFF',
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
              mb: 2,
              py: 2,
            }}
          >
            <Card
              sx={{
                width: '100%',
                px: 2,
                py: 1,
                bgcolor: '#FFFFFF',
                borderBottom: '1px solid #ced4da',
                borderRadius: 0,
                boxShadow: 'none',
              }}
            >
              <Stack direction="row" sx={{ pb: 1 }}>
                <Stack direction="column" sx={{ minWidth: '20%' }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                  >
                    Work email
                  </Typography>
                </Stack>
                <Stack direction="column" sx={{ width: '100%' }} gap={0.5}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      border: `1px solid ${
                        formik.errors.firstName ? '#F65D5E' : '#E2E2E2'
                      }`,
                      borderRadius: '4px',
                      width: '100%',
                    }}
                  >
                    <CustomTextFieldNoBorders
                      value={formik.values.email}
                      fullWidth
                      placeholder="Type here"
                      onChange={(e) => {
                        formik.setFieldValue('email', e.target.value);
                      }}
                    />
                  </Stack>
                  {Boolean(formik.errors.email) && (
                    <Typography color="error" gutterBottom variant="body2">
                      {formik.errors.email}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Card>

            <Card
              sx={{
                width: '100%',
                px: 2,
                py: 1,
                bgcolor: '#FFFFFF',
                borderRadius: 0,
                boxShadow: 'none',
              }}
            >
              <Stack direction="column" gap={1}>
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                    >
                      Create Password
                    </Typography>
                  </Stack>
                  <Stack direction="column" sx={{ width: '100%' }} gap={0.5}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        border: `1px solid ${
                          formik.errors.firstName ? '#F65D5E' : '#E2E2E2'
                        }`,
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      <CustomTextFieldNoBorders
                        value={formik.values.password}
                        fullWidth
                        placeholder="Type here"
                        type={values.showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                          formik.setFieldValue('password', e.target.value);
                        }}
                      />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </Stack>
                    {Boolean(
                      formik.touched.password && formik.errors.password,
                    ) && (
                      <Typography color="error" gutterBottom variant="body2">
                        {formik.touched.password && formik.errors.password}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                    >
                      Repeat Password
                    </Typography>
                  </Stack>
                  <Stack direction="column" sx={{ width: '100%' }} gap={0.5}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        border: `1px solid ${
                          formik.errors.firstName ? '#F65D5E' : '#E2E2E2'
                        }`,
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      <CustomTextFieldNoBorders
                        type={values.showRepeatPassword ? 'text' : 'password'}
                        value={formik.values.reEnterPassword}
                        fullWidth
                        sx={{ p: 0 }}
                        placeholder="Type here"
                        onChange={(e) => {
                          formik.setFieldValue(
                            'reEnterPassword',
                            e.target.value,
                          );
                        }}
                      />
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showRepeatPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </Stack>
                    {Boolean(
                      formik.touched.reEnterPassword &&
                        formik.errors.reEnterPassword,
                    ) && (
                      <Typography color="error" gutterBottom variant="body2">
                        {formik.touched.reEnterPassword &&
                          formik.errors.reEnterPassword}
                      </Typography>
                    )}
                    {showPasswordReqs && (
                      <Typography color="error" gutterBottom variant="body2">
                        Password must contain at least 8 characters, at least
                        one uppercase, at least one lowercase, at least one
                        number, and one special case character.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          {needsValidation && (
            <Stack direction="column" gap={2}>
              <Typography color={darkTextColor} gutterBottom variant="h6">
                Verify your identity
              </Typography>
              <Card sx={{ width: '100%', px: 5, py: 3 }}>
                <Grid
                  gap={4}
                  container
                  direction="row"
                  sx={{ my: 2, flexWrap: 'nowrap' }}
                  alignItems="center"
                >
                  <Grid item>
                    {selectedImage ? (
                      <img
                        alt="verify-id"
                        style={{ width: 125 }}
                        src={URL.createObjectURL(selectedImage[0])}
                      />
                    ) : (
                      <DefaultImage />
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      component="label"
                      sx={{ width: '8rem', height: 40, mb: 2 }}
                    >
                      Upload
                      <input
                        hidden
                        accept="image/png, image/svg, image/jpeg"
                        onChange={(event) => {
                          if (event.target.files) {
                            const name = event.target.files[0].name;
                            const fileExtension = name.split('.').pop();
                            if (
                              fileExtension === 'png' ||
                              fileExtension === 'jpeg' ||
                              fileExtension === 'svg' ||
                              fileExtension === 'jpg'
                            ) {
                              var img = new Image();
                              img.src = URL.createObjectURL(
                                event.target.files[0],
                              );
                              img.onload = () => {
                                if (img.width >= 200 && img.height >= 100) {
                                  setImageErr();
                                  setSelectedImage(event.target.files);
                                } else {
                                  setImageErr(
                                    `Image dimensions should be 200x100px. Image wasn’t uploaded because it is ${img.width}x${img.height}px.`,
                                  );
                                  console.log('image dimensions are to small');
                                }
                              };
                            } else {
                              setImageErr(
                                `Files extension dont match. Please upload in the following formats PNG or JPEG`,
                              );
                            }
                          }
                        }}
                        type="file"
                      />
                    </Button>
                    <Stack direction="column">
                      <Grid
                        gap={1}
                        container
                        direction="row"
                        alignItems="start"
                      >
                        <div style={{ marginTop: 1 }}>
                          <TooltipIcon />
                        </div>
                        <Typography
                          color={darkTextColor}
                          sx={{ opacity: 0.75 }}
                          gutterBottom
                          variant="body2"
                        >
                          Upload a photo of your work badge or ID.
                          <br />
                          PNG or JPEG (min. 200x100px)
                        </Typography>
                      </Grid>
                      {imageErr ? (
                        imageErr.split('.').map((errMess) => (
                          <Typography
                            color="error"
                            sx={{ opacity: 0.75, m: 0 }}
                            gutterBottom
                            variant="body2"
                          >
                            {errMess}
                          </Typography>
                        ))
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <Stack
                  gap={1}
                  direction="row"
                  alignItems="flex-start"
                  sx={{ mt: 2, flexWrap: 'nowrap' }}
                >
                  <Box sx={{ mb: 0 }}>
                    <TooltipIcon fill="#F65D5E" opacity={0.75} size={16} />
                  </Box>
                  <Typography
                    color="error"
                    sx={{ opacity: 0.75 }}
                    gutterBottom
                    variant="body2"
                  >
                    We only ask new accounts without an approved domain to
                    upload a photo of their work badge or ID.
                  </Typography>
                </Stack>
              </Card>
            </Stack>
          )}
        </form>
      </Grid>
    </>
  );
};

export default CreateAccount;
