import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  FormControlLabel,
  Radio,
  Autocomplete,
  Chip,
  Popper,
  Stack,
  IconButton,
} from '@mui/material';
import CustomInputField from '../../../components/customInputField';
import CloseLight from '../../../Icons/closeLight';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddressField from './addressField';
import RemoveButton from '../../../components/removeButton';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
import {
  darkTextColor,
  defaultAvailability,
  getEmployer,
  getEmployerList,
  handleFormatedAddress,
} from '../../../constants';
import ListOfInsurances from '../../../constants/insurances';
import { CircleButton } from '../../EditProfile/circleButton';
import { TextMaskCustom } from '../../../components/phoneNumberField';
import { useRef } from 'react';

// function insert(str, index, value) {
//   return str.substr(0, index) + value + str.substr(index);
// }

const YourPractice = (props) => {
  const {
    formik,
    handleAddInsurance,
    practiceData,
    handleUpdatePracticeData,
    handleRemoveAddress,
    handleSetPrimaryAddress,
    handleRemoveInsurance,
    isDrawerOpen,
  } = props;

  const [insurance, setInsurance] = useState('');
  const [hospitalLocations, setHospitalLocation] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [customAvailablity, setCustomAvailablity] = useState(false);
  const [currentHospital, setCurrentHospital] = useState('');
  const refCustomField = useRef();
  const updatePlaces = (places) => {
    try {
      if (places) {
        const { formatted_address, vicinity, name, formatted_phone_number } =
          places[0];
        const postal_code = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'postal_code'),
        );
        const city = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'locality'),
        );

        const state = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'administrative_area_level_1'),
        );
        handleUpdatePracticeData('addresses', {
          formatted_address: vicinity,
          full_address: formatted_address,
          name,
          postal_code: postal_code.long_name || '',
          latitude:
            places[0].geometry.location.lat() ||
            places[0].geometry.viewport.ab.lo,
          longitude:
            places[0].geometry.location.lng() ||
            places[0].geometry.viewport.Fa.lo,
          isPrimary: practiceData?.addresses?.length ? false : true,
          city: city.long_name || '',
          state: state.short_name || '',
          // stateShortName: state.short_name || '',
          telephone: formatted_phone_number
            ?.replace('(', '')
            .replace(')', '')
            .replace(' ', '-'),
        });
      } else {
        handleUpdatePracticeData('addresses', 'Please enter an address', true);
      }
    } catch {
      handleUpdatePracticeData(
        'addresses',
        'Please enter in a full street address.',
        true,
      );
    }
  };

  useEffect(() => {
    if (practiceData.healthCareEmployer) {
      const employer = getEmployer(practiceData.healthCareEmployer);
      if (employer && employer?.locations) {
        setHospitalLocation(employer.locations);
      }
    }
  }, [practiceData.healthCareEmployer]);

  const healthCareEmployerOptions = getEmployerList();

  useEffect(() => {
    if (healthCareEmployerOptions.includes(practiceData.healthCareEmployer)) {
      setShowLocation(true);
    } else {
      setShowLocation(false);
    }
  }, [practiceData]);

  const handleClear = () => {
    setInsurance('');
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="column"
        alignItems="start"
        sx={{
          alignSelf: 'start',
          ml: isDrawerOpen ? 40 : 0,
          overflowY: 'auto',
          px: 1,
          width: '80vw',
          maxWidth: 950,
        }}
      >
        <form
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Stack direction="column">
            <Stack direction="column" sx={{ mt: 2 }}>
              <Typography
                color={darkTextColor}
                variant="h5"
                fontSize="18px"
                sx={{ fontWeight: 600 }}
              >
                Your practice
              </Typography>
              <Typography color={darkTextColor} fontSize="14px" sx={{ my: 1 }}>
                Share your office locations and accepted insurances for easy
                referrals.
              </Typography>
            </Stack>
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                bgcolor: '#FFFFFF',
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
              }}
              gap={0}
            >
              <Grid
                maxWidth={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                gap={2}
                sx={{ py: 2, width: '100%' }}
              >
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Healthcare Employer
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack sx={{ width: '100%' }} gap={1}>
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            p: 0,
                            m: 0,
                            width: '100%',
                            borderRadius: '4px',
                          }}
                        >
                          <Autocomplete
                            freeSolo
                            disablePortal
                            id="healthCareEmployer"
                            name="healthCareEmployer"
                            PopperComponent={({ style, ...props }) => (
                              <Popper
                                {...props}
                                style={{ ...style, height: 0 }}
                              />
                            )}
                            options={healthCareEmployerOptions || []}
                            value={practiceData.healthCareEmployer || ''}
                            onChange={(e, value) => {
                              handleUpdatePracticeData(
                                'healthCareEmployer',
                                value,
                              );
                              handleUpdatePracticeData('hospitalLocation', []);
                              if (healthCareEmployerOptions.includes(value)) {
                                setShowLocation(true);
                              } else {
                                setShowLocation(false);
                              }
                            }}
                            sx={{ width: '100%', color: 'black', p: 0 }}
                            renderInput={(params) => (
                              <CustomTextFieldNoBorders
                                {...params}
                                onInput={(e) => {
                                  e.target.value = e.target.value.slice(0, 75);
                                }}
                                onChange={(e) => {
                                  handleUpdatePracticeData(
                                    'healthCareEmployer',
                                    e.target.value,
                                  );
                                  handleUpdatePracticeData(
                                    'hospitalLocation',
                                    [],
                                  );
                                  if (
                                    healthCareEmployerOptions.includes(
                                      e.target.value,
                                    )
                                  ) {
                                    setShowLocation(true);
                                  } else {
                                    setShowLocation(false);
                                  }
                                }}
                              />
                            )}
                          />
                        </Stack>
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                        >
                          Feel free to add in your employer if it is not in the
                          list.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
                {practiceData.healthCareEmployer && showLocation && (
                  <Card
                    sx={{
                      width: '100%',
                      px: 2,
                      py: 1,
                      bgcolor: '#FFFFFF',
                      borderBottom: '1px solid #ced4da',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                  >
                    <Stack direction="row" sx={{ pb: 1 }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                        fontSize="14px"
                        sx={{ minWidth: '20%' }}
                      >
                        Hospital Locations
                      </Typography>
                      <Grid
                        container
                        gap={1}
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', mb: 1 }}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          gap={2}
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                          }}
                        >
                          <Grid item sx={{ flexGrow: 9 }}>
                            <Autocomplete
                              freeSolo
                              id="locations-auto-complete"
                              options={hospitalLocations || []}
                              getOptionDisabled={(option) =>
                                practiceData?.hospitalLocation?.includes(option)
                              }
                              PopperComponent={({ style, ...props }) => (
                                <Popper
                                  {...props}
                                  style={{ ...style, height: 0 }}
                                />
                              )}
                              value={currentHospital || ''}
                              ListboxProps={{ style: { maxHeight: '20vh' } }}
                              onInputChange={(event, value) => {
                                setCurrentHospital(value);
                              }}
                              sx={{
                                width: '100%',
                                color: 'black',
                                p: 0,
                              }}
                              renderInput={(params) => (
                                <CustomTextFieldNoBorders
                                  {...params}
                                  placeholder="Search for a hospital"
                                  value={currentHospital || ''}
                                />
                              )}
                            />
                          </Grid>
                          <CircleButton
                            onClick={() => {
                              if (currentHospital) {
                                const updatedArray = [
                                  ...practiceData.hospitalLocation,
                                  currentHospital,
                                ];
                                setCurrentHospital('');
                                handleUpdatePracticeData(
                                  'hospitalLocation',
                                  updatedArray,
                                );
                              }
                            }}
                          />
                        </Grid>
                        {practiceData?.hospitalLocation &&
                          practiceData?.hospitalLocation?.map((item) => (
                            <Card
                              justifyContent="space-between"
                              sx={{
                                width: '100%',
                                py: 1,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                boxShadow: 0,
                                border: '1px solid #E2E2E2',
                              }}
                            >
                              <Typography color={darkTextColor} variant="body1">
                                {item}
                              </Typography>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  gap={4}
                                  alignItems="center"
                                >
                                  <IconButton
                                    disableRipple
                                    onClick={() => {
                                      const updatedArray =
                                        practiceData?.hospitalLocation?.filter(
                                          (text) => text !== item,
                                        );
                                      handleUpdatePracticeData(
                                        'hospitalLocation',
                                        updatedArray,
                                      );
                                    }}
                                  >
                                    <CloseLight />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Card>
                          ))}
                      </Grid>
                    </Stack>
                  </Card>
                )}
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Office Locations
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Grid
                          container
                          gap={1}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: '100%' }}
                        >
                          <AddressField updatePlaces={updatePlaces} />
                        </Grid>
                      </Stack>
                      {practiceData?.addresses?.length ? (
                        practiceData?.addresses.map(
                          (
                            {
                              formatted_address,
                              name,
                              isPrimary,
                              full_address,
                              city,
                              postal_code,
                              state,
                            },
                            index,
                          ) => (
                            <Card
                              key={`${name}-${index}`}
                              sx={{
                                p: 2,
                                pr: 10,
                                maxWidth: { md: '48%', sm: '90%' },
                                width: { md: '48%', sm: '90%' },
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                border: '1px solid #E2E2E2',
                                boxShadow: 'none',
                              }}
                            >
                              <Typography
                                color={darkTextColor}
                                variant="body1"
                                sx={{
                                  cursor: 'pointer',
                                  position: 'absolute',
                                  right: '5%',
                                }}
                                onClick={() =>
                                  handleRemoveAddress(formatted_address)
                                }
                              >
                                <CloseLight />
                              </Typography>
                              <Grid>
                                <Typography
                                  color={darkTextColor}
                                  variant="h6"
                                  fontSize="14px"
                                  sx={{ width: '90%' }}
                                >
                                  {name}
                                </Typography>
                                <Typography
                                  color={darkTextColor}
                                  variant="body2"
                                  fontSize="14px"
                                  sx={{ width: '95%', my: 1 }}
                                >
                                  {handleFormatedAddress(formatted_address)}
                                  {city ? `${city}, ` : ''}
                                  {state ? `${state} ` : ''} {postal_code}
                                </Typography>
                              </Grid>
                              <FormControlLabel
                                checked={isPrimary}
                                onChange={() =>
                                  handleSetPrimaryAddress(formatted_address)
                                }
                                value={isPrimary ? 'Primary' : 'Set as primary'}
                                label={isPrimary ? 'Primary' : 'Set as primary'}
                                control={<Radio />}
                                sx={{
                                  color: darkTextColor,
                                  fontSize: '0.5rem',
                                }}
                              />
                            </Card>
                          ),
                        )
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderRadius: 0,
                    boxShadow: 'none',
                    borderBottom: '1px solid #ced4da',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                        sx={{ width: '50%' }}
                      >
                        In-network insurances
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack sx={{ width: '100%' }} gap={1}>
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                            p: 0,
                            m: 0,
                            width: '100%',
                          }}
                        >
                          <Grid
                            container
                            gap={1}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ width: '100%', flexWrap: 'nowrap' }}
                          >
                            <Autocomplete
                              key={practiceData?.insurance?.length}
                              disablePortal
                              getOptionDisabled={(option) =>
                                !!practiceData?.insurance?.find(
                                  (insurance) =>
                                    insurance?.toLowerCase() ===
                                    option.toLocaleLowerCase(),
                                )
                              }
                              freeSolo
                              id="In-network-insurances-input"
                              name="In-network insurances"
                              placeholder="Type to search"
                              options={ListOfInsurances}
                              value={insurance}
                              onChange={(e, value) => {
                                if (value) {
                                  handleAddInsurance(value);
                                  handleClear();
                                }
                              }}
                              fullWidth
                              sx={{ color: 'black', p: 0 }}
                              renderInput={(params) => (
                                <CustomTextFieldNoBorders
                                  ref={refCustomField}
                                  {...params}
                                  onChange={(e) => {
                                    setInsurance(e.target.value?.slice(0, 75));
                                  }}
                                  placeholder="Search for an insurance provider"
                                />
                              )}
                            />
                            <CircleButton
                              onClick={() => {
                                if (insurance?.trim) {
                                  setInsurance('');
                                  handleAddInsurance(insurance);
                                }
                              }}
                            />
                          </Grid>
                        </Stack>
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                        >
                          Feel free to add in your insurance if it is not in the
                          list.
                        </Typography>
                      </Stack>
                      {practiceData?.insurance?.length ? (
                        practiceData?.insurance
                          .sort((a, b) => ('' + a).localeCompare(b))
                          .map((insurance) => (
                            <Card
                              sx={{
                                p: 1,
                                py: 1,
                                border: '1px solid #E2E2E2',
                                boxShadow: 'none',
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                gap={4}
                              >
                                <Typography
                                  color={darkTextColor}
                                  gutterBottom
                                  variant="body1"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: 0,
                                  }}
                                >
                                  {insurance} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;
                                </Typography>
                                <RemoveButton
                                  handleClick={() =>
                                    handleRemoveInsurance(insurance)
                                  }
                                />
                              </Grid>
                            </Card>
                          ))
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Office Number
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        gap={1}
                      >
                        <CustomInputField
                          error={Boolean(
                            formik.touched.officeNumber &&
                              formik.errors.officeNumber,
                          )}
                          sx={{
                            width: '100%',
                            border: '1px solid #E2E2E2 !important',
                            borderRadius: '4px',
                            '& .MuiInputBase-input': {
                              background: 'transparent',
                              border: '0px solid !important',
                            },
                          }}
                          fullWidth
                          hiddenLabel
                          name="officeNumber"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="(xxx) xxx-xxxx"
                          value={formik.values.officeNumber}
                          inputComponent={TextMaskCustom}
                        />
                        {Boolean(
                          formik.touched.officeNumber &&
                            formik.errors.officeNumber,
                        ) && (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.touched.officeNumber &&
                              formik.errors.officeNumber}
                          </Typography>
                        )}
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                        >
                          Physicians may provide this phone number to patients
                          for follow-up or to ensure continuity of care.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>

                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                        sx={{ minWidth: '20%' }}
                      >
                        Referral-to-Visit Time Estimate:
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack direction="row" flexWrap="wrap" gap={1}>
                        {defaultAvailability.map((text) => (
                          <Chip
                            variant="outlined"
                            color={
                              formik.values.availabilityTime === text ||
                              (text === 'Custom' && customAvailablity)
                                ? 'primary'
                                : 'info'
                            }
                            sx={{ width: 'max-content', px: 2 }}
                            label={text}
                            onClick={() => {
                              if (text === 'Custom') {
                                setCustomAvailablity(true);
                                formik.setFieldValue('availabilityTime', '');
                              } else {
                                if (customAvailablity) {
                                  setCustomAvailablity(false);
                                }
                                formik.setFieldValue('availabilityTime', text);
                              }
                            }}
                          />
                        ))}
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                            width: '100%',
                          }}
                        >
                          <CustomTextFieldNoBorders
                            value={formik.values.availabilityTime}
                            fullWidth
                            placeholder="Type here"
                            disabled={!customAvailablity}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'availabilityTime',
                                e.target.value,
                              );
                            }}
                            inputProps={{ maxLength: 225 }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Grid>
    </LocalizationProvider>
  );
};

export default YourPractice;
