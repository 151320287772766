import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Pagination,
  Select,
  MenuItem,
  TableSortLabel,
  FormControl,
  InputAdornment,
  Button,
} from '@mui/material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  encryptNumber,
  getAge,
  NotificationColors,
  GetComparator,
  StableSort,
  TableHeaders,
  RESPONSE_STATUS,
} from '../../constants';
import * as moment from 'moment';
import FlagFeedback from '../../components/flagFeedback/flagFeedback';
import { NotificationNumber } from '../../components/notificationNumber';
import { Stack } from '@mui/system';
import TooltipIcon from '../../Icons/tooltipIcon';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomChip from '../../components/customChip';
import { Chip } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import FlagIcon from '@mui/icons-material/Flag';
import CodeIcon from '@mui/icons-material/Code';
import UserContext from '../../contexts/user/context';
import Favorites from '../../services/favorites';
import Users from '../../services/users';
import { EndorsementColumn } from './endorsementColumn';

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}

const checkFav = (favList, userId) => {
  if (favList.length) {
    const findFav = favList.find((f) => f.favoriteId === userId);
    if (findFav) {
      return true;
    }
  }
  return false;
};

const RefferedTo = ({ referralData }) => {
  let text = 'To';

  if (referralData.forwardRef?.ReferredBy) {
    text = 'Forwarded to';
  }
  if (
    referralData?.unRegisteredUser &&
    referralData?.referredToIdUnRegistered
  ) {
    return `${text} ${CapitalizeFirstLetter(
      referralData?.unRegisteredUser?.first_name,
    )}
  ${CapitalizeFirstLetter(referralData?.unRegisteredUser?.last_name)}${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix ? ', ' : ''
    }${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
        ? referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
            ?.replaceAll('.', '')
            ?.replaceAll(',', ', ')
        : ''
    }`;
  }
  return `${text} ${CapitalizeFirstLetter(referralData?.ReferredTo?.firstName)}
  ${CapitalizeFirstLetter(referralData?.ReferredTo?.lastName)},${
    referralData?.ReferredTo?.prefix
      ? ` ${referralData?.ReferredTo?.prefix?.replaceAll(',', ', ')}`
      : ''
  }`;
};

const getReferralStatus = (status) => {
  if (status === null) {
    return 'Pending';
  }
  if (status === 'accept') {
    return 'Accepted';
  }
  if (status === 'decline') {
    return 'Declined';
  }
  return capitalizeFirstLetter(status);
};

const options = [
  {
    text: 'Last 30 days',
    value: 30,
  },
  {
    text: 'Last 3 months',
    value: 91,
  },
  {
    text: 'Last 6 months',
    value: 183,
  },
  {
    text: 'Last year',
    value: 365,
  },
  {
    text: 'Show all',
    value: 1000000,
  },
];

const referralOptions = [
  {
    text: 'Accepted',
    value: 'accept',
  },
  {
    text: 'Declined',
    value: 'decline',
  },
  {
    text: 'Show all',
    value: 'all',
  },
];

const HistoryTable = ({ data, heading, user }) => {
  const [docData, setDocData] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableData, setTableData] = React.useState();
  const [filterTime, setFilterTime] = React.useState(183);
  const [filterReferrals, setFilterReferrals] = React.useState('all');
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [clicks, setClicks] = React.useState([]);
  const [openFlag, setOpenFlag] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [likes, setLikes] = React.useState(docData?.likes || 0);
  const [endorsements, setEndorsements] = React.useState(
    docData?.endorsements || [],
  );
  const [favLoading, setFavLoading] = React.useState(false);
  const [isFav, setIsFav] = React.useState();
  const { token } = useContext(UserContext);
  const [flaggedDoc, setFlaggedDoc] = React.useState('');

  const updateFav = (favorites) => {
    const fav = checkFav(favorites, docData.id);
    setIsFav(fav);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePagePagination = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        const response = await Users.getById(token, user.id);
        // const referral = await Patients.getById(token, user.id)
        if (response.status === RESPONSE_STATUS.error) {
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          // setLikes(referral.ReferredTo?.favoritesOff?.length || 0);
          // setEndorsements(referral.ReferredTo?.favoritesOff || []);
          setDocData(response.body);
        }
      } catch (e) {
        console.log(e.message);
        return;
      } finally {
        setLoading(false);
      }
    };

    if (user.id && token) {
      getData();
    }
  }, [user.id, token]);

  React.useEffect(() => {
    if (docData && isFav === undefined && user) {
      const getFav = checkFav(user.favorites, docData.id);
      setIsFav(getFav);
    }
  }, [docData, user]);

  const AddFavorites = async (docId) => {
    try {
      setFavLoading(true);
      const response = await Favorites.post(token, docId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const RemoveFavorites = async (docId) => {
    try {
      setFavLoading(true);

      const response = await Favorites.put(token, docId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  React.useEffect(() => {
    if (data?.length) {
      const now = new Date();
      const filtered = data?.filter((d) => {
        if (d.status) {
          const diff =
            Math.abs(new Date(d.createdAt).getTime() - now.getTime()) /
            (24 * 60 * 60 * 1000);
          if (diff < filterTime) {
            return d;
          }
        }
      });
      setTableData(filtered);
    }
  }, [data, filterTime]);

  // React.useEffect(() => {
  //   if (data.length) {
  //     const filtered = data.filter((d) => {
  //       if (d.status) {
  //         clicks ? <BlockIcon /> : <AddCircleIcon />
  //         if (diff < filterTime) {
  //           return d;
  //         }
  //       }
  //     });
  //     setTableData(filtered);
  //   }
  // }, [data, clicks]);

  React.useEffect(() => {
    if (data?.length) {
      const filtered = data?.filter((d) => {
        if (d.status) {
          if (filterReferrals == 'all') {
            return d;
          } else {
            if (d.status == filterReferrals) {
              return d;
            }
          }
        }
      });
      setTableData(filtered);
    }
  }, [data, filterReferrals]);

  React.useEffect(() => {
    if (data?.length) {
      const filtered = data?.filter((d) => {
        if (d.status) {
          if (!searchQuery) {
            return d;
          } else {
            return (
              d.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
              d.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
              d.ReferredBy.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              d.ReferredBy.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              d.ReferredTo.firstName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              d.ReferredTo.lastName
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              d.reason.toLowerCase().includes(searchQuery.toLowerCase())
            );
          }
        }
      });
      setTableData(filtered);
    }
  }, [data, searchQuery]);

  const handleChange = (event) => {
    setFilterTime(event.target.value);
  };

  const handleChangeReferral = (event) => {
    setFilterReferrals(event.target.value);
  };

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleIconClick = (id) => {
    if (clicks.contains(id)) {
      clicks.remove(id);
    } else {
      clicks.add(id);
      let result = clicks.includes(id)
        ? clicks.filter((click) => click != id)
        : [...clicks, id];
      setClicks(result);
    }
  };

  const handleOpenFlag = (e) => {
    setOpenFlag(true);
  };

  const handleCloseFlag = (e) => {
    e.stopPropagation();
    setOpenFlag(false);
    setFlaggedDoc('');
  };

  // create a component from this dialog

  return (
    <>
      <FlagFeedback
        flaggedDoc={flaggedDoc}
        openDialog={openFlag}
        setOpenDialog={setOpenFlag}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="flex-end" spacing={4} sx={{ my: 2 }}>
          <Stack direction="row" alignItems="flex-end" sx={{ my: 2 }}>
            <Typography
              color={darkTextColor}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                m: 0,
                alignItem: 'center',
              }}
              gutterBottom
              variant="h5"
            >
              {heading}&nbsp;
            </Typography>
            {
              <NotificationNumber
                bgcolor="rgba(33,38,52,0.1)"
                number={tableData?.length}
                variant="h6"
                color="#212634"
              />
            }
          </Stack>

          <TextField
            InputLabelProps={{ style: { pointerEvents: 'auto' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              input: {
                color: 'black',
              },
              width: '25em',
            }}
            placeholder="Medical specialist, patient, ..."
            variant="outlined"
            type="text"
            fullWidth
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </Stack>
        <Stack direction="row" alignItems="flex-end" sx={{ my: 1 }}>
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 180 }}>
            <Select
              value={filterReferrals}
              onChange={handleChangeReferral}
              displayEmpty
              sx={{
                color: darkTextColor,
                fontSize: 'body1',
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {referralOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ color: darkTextColor }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <span>{option.text}</span>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 180 }}>
            <Select
              value={filterTime}
              onChange={handleChange}
              displayEmpty
              sx={{
                color: darkTextColor,
                fontSize: 'body1',
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {options.map((option) => (
                <MenuItem value={option.value} sx={{ color: darkTextColor }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Tooltip title="Calendar">
                      <CalendarTodayIcon />
                    </Tooltip>
                    <span>{option.text}</span>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <Table>
        <colgroup>
          <col style={{ width: '10%' }} />
          <col style={{ width: '12.5%' }} />
          <col style={{ width: '12.5%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {TableHeaders.map((header, i) => (
              <TableCell
                style={{
                  fontWeight: '400',
                  fontSize: '1em',
                  textTransform: 'None',
                }}
              >
                <TableSortLabel
                  active={header.orderBy === orderBy}
                  direction={header.orderBy === orderBy ? order : 'asc'}
                  onClick={() => handleRequestSort(i, header.orderBy)}
                  hideSortIcon={orderBy === orderBy}
                  IconComponent={CodeIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      transform: 'rotate(90deg)',
                      color: '#000000 !important',
                      stroke: '#FFFFFF',
                      strokeWidth: 0.4,
                    },
                    color: header.orderBy === orderBy && '#000000 !important',
                  }}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ alignItems: 'center', cursor: 'pointer' }}>
          {tableData &&
            StableSort(tableData, GetComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, i) => (
                <TableRow
                  onClick={() => {
                    const profileNumber = encryptNumber(data.id);
                    navigate(`/patient/${profileNumber}`);
                  }}
                  className="mui-table-row"
                  sx={{ m: 5 }}
                >
                  <TableCell>
                    <Typography color={darkTextColor}>
                      {data.firstname}&nbsp;{data.lastname}
                    </Typography>
                    <Typography color={darkTextColor}>
                      {getAge(data?.dateOfBirth)} y.o. {data?.sex}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CustomChip
                      type={data?.levelOfUrgency}
                      sx={{
                        height: '1.5em',
                        width: 'fit-content',
                        fontSize: '1.1em',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={getReferralStatus(data?.status)}
                      sx={{
                        color:
                          data?.status === null
                            ? NotificationColors.pending.color
                            : data?.status === 'accept'
                            ? NotificationColors.success.color
                            : data?.status === 'decline'
                            ? NotificationColors.error.color
                            : NotificationColors.dark.color,
                        backgroundColor:
                          data?.status === null
                            ? NotificationColors.pending.color
                            : data?.status === 'accept'
                            ? NotificationColors.success.bgcolor
                            : data?.status === 'decline'
                            ? NotificationColors.error.bgcolor
                            : NotificationColors.dark.bgcolor,
                        fontWeight: 500,
                        p: 0,
                        borderRadius: '4px',
                        height: '1.5em',
                        width: 'fit-content',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {data?.ReferralFrom && data?.referralFrom === user?.id ? (
                      <Typography variant="body1" color={darkTextColor}>
                        {`to ${CapitalizeFirstLetter(
                          data?.ReferredBy?.firstName,
                        )} ${CapitalizeFirstLetter(
                          data?.ReferredBy?.lastName,
                        )}, ${
                          data?.ReferredBy?.prefix?.replaceAll(',', ', ') || ''
                        }
                  `}
                        {`Forwarded to ${CapitalizeFirstLetter(
                          data?.ReferredTo?.firstName,
                        )} ${CapitalizeFirstLetter(
                          data?.ReferredTo?.lastName,
                        )}, ${
                          data?.ReferredTo?.prefix?.replaceAll(',', ', ') || ''
                        }
                  `}
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ flexWrap: 'nowrap' }}
                        >
                          <span>{moment(data.createdAt).fromNow()}</span>
                          <Tooltip
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: '#FFFFFF',
                                  backgroundColor: '#212634',
                                },
                              },
                              arrow: {
                                sx: {
                                  color: '#212634',
                                },
                              },
                            }}
                            title="Responding within less than 5 days will improve your chances of receiving new referrals."
                          >
                            <p style={{ marginTop: '5px' }}>
                              <TooltipIcon />
                            </p>
                          </Tooltip>
                        </Stack>
                      </Typography>
                    ) : (
                      <Typography color={darkTextColor}>
                        <span>{moment(data.createdAt).fromNow()}</span>
                        {', '}
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ flexWrap: 'nowrap' }}
                        >
                          <span>
                            {data.forwardRef?.ReferredBy ? (
                              `by ${CapitalizeFirstLetter(
                                data?.forwardRef?.ReferredBy?.firstName,
                              )}
                          ${CapitalizeFirstLetter(
                            data?.forwardRef?.ReferredBy?.lastName,
                          )},
                          ${
                            data?.forwardRef?.ReferredBy?.prefix?.replaceAll(
                              ',',
                              ', ',
                            ) || ''
                          } `
                            ) : (
                              <></>
                            )}
                            {user.id === data?.doctorId ? (
                              <RefferedTo referralData={data} />
                            ) : (
                              `${
                                data.forwardRef?.ReferredBy
                                  ? 'Forwarded by'
                                  : 'by'
                              } ${CapitalizeFirstLetter(
                                data?.ReferredBy?.firstName,
                              )}
                        ${CapitalizeFirstLetter(data?.ReferredBy?.lastName)},
                        ${
                          data?.ReferredBy?.prefix?.replaceAll(',', ', ') || ''
                        }`
                            )}
                          </span>
                          <Tooltip
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: '#FFFFFF',
                                  backgroundColor: '#212634',
                                },
                              },
                              arrow: {
                                sx: {
                                  color: '#212634',
                                },
                              },
                            }}
                            title="Responding within less than 5 days will improve your chances of receiving new referrals."
                          >
                            <p style={{ marginTop: '5px' }}>
                              <TooltipIcon />
                            </p>
                          </Tooltip>
                        </Stack>
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: '#FFFFFF',
                            backgroundColor: '#212634',
                          },
                        },
                        arrow: {
                          sx: {
                            color: '#212634',
                          },
                        },
                      }}
                      title={'Click to open referral details'}
                    >
                      <Typography
                        color={darkTextColor}
                        sx={{
                          bgcolor: 'transparent',
                          overflowWrap: 'anywhere',
                        }}
                        className="referral-table-reason"
                      >
                        {data.reason}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={2}
                      sx={{
                        flexWrap: { md: 'nowrap', sm: 'wrap' },
                        justifyContent: { md: 'end', sm: 'center' },
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={0}>
                        {data?.ReferredTo || data?.ReferredBy ? (
                          <EndorsementColumn
                            data={data}
                            user={user}
                            favs={user.favorites}
                            token={token}
                          />
                        ) : (
                          <></>
                        )}
                      </Stack>
                      <Button
                        aria-label="flag"
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          const elem =
                            data?.doctorId == user?.id
                              ? data?.ReferredTo
                              : data?.ReferredBy;
                          const flaggedDocName =
                            elem?.firstName + ' ' + elem?.lastName;
                          setFlaggedDoc(flaggedDocName);
                          handleOpenFlag();
                        }}
                        sx={{
                          borderRadius: 0,
                          border: '1px solid',
                          color: '#000000',
                          borderColor: NotificationColors.dark.bgcolor,
                          '& .MuiButton-startIcon': { margin: 0 },
                        }}
                        startIcon={<FlagIcon />}
                      ></Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Stack
        sx={{
          color: 'black !important',
          position: 'relative',
          borderTop: '1px solid #E2E2E2',
          p: 1,
        }}
        direction="row"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '20%' }}
        >
          <Typography
            variant="body1"
            sx={{ opacity: 0.5 }}
            color={darkTextColor}
          >
            View
          </Typography>
          <Select
            value={rowsPerPage}
            options={[1, 25, 50]}
            onChange={(event) => setRowsPerPage(event.target.value)}
            // displayEmpty
            defaultValue={rowsPerPage}
            sx={{
              color: darkTextColor,
              fontSize: 'body1',
              width: '30%',
              height: 40,
            }}
          >
            {[5, 10, 25, 50].map((value) => (
              <MenuItem sx={{ color: darkTextColor }} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="body1"
            color={darkTextColor}
            sx={{ width: '100%', opacity: 0.5 }}
          >
            referrals per page
          </Typography>
        </Stack>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
          }}
        >
          <Pagination
            count={
              tableData?.length / rowsPerPage >= 1
                ? Math.ceil(tableData?.length / rowsPerPage)
                : 1
            }
            // count={5}
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={handleChangePagePagination}
          />
        </div>
        {/* )} */}
      </Stack>
    </>
  );
};

export default HistoryTable;
