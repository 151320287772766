import { Typography, Grid, Stack, Radio, Switch } from '@mui/material';
import CustomInputField from '../../components/customInputField';
import { darkTextColor } from '../../constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import dayjs from 'dayjs';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const PatientDetails = ({
  disabled = false,
  formik,
  page,
  ownProfile = false,
}) => {
  const [dob, setDob] = useState(dayjs());
  const handleSexChange = (value) => {
    formik.setFieldValue('sex', value);
  };

  const isFemale = formik.values.sex === 'female';

  // useEffect(() => {
  //   if (formik.values.dateOfBirth !== dob) {
  //     console.log(formik.values.dateOfBirth);
  //     setDob(formik.values.dateOfBirth);
  //   }
  // }, [formik]);

  const handleUpdateDate = (e) => {
    if (e.target.value.length < formik.values.dateOfBirth.length) {
      formik.setFieldValue('dateOfBirth', e.target.value);
      return;
    }
    const inputDate = e.target.value;
    let formattedDate = inputDate.replace(/\//g, ''); // Remove existing slashes
    if (formattedDate.length > 2 && formattedDate.length < 5) {
      formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
    } else if (formattedDate.length > 4) {
      formattedDate =
        formattedDate.slice(0, 2) +
        '/' +
        formattedDate.slice(2, 4) +
        '/' +
        formattedDate.slice(4, 8);
    }
    formik.setFieldValue('dateOfBirth', formattedDate);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form>
        <Grid
          container
          direction="column"
          gap={1}
          sx={{ flexWrap: 'nowrap', overflowY: 'auto', pr: 0.1 }}
        >
          <Grid container direction="column">
            <Typography color={darkTextColor} variant="body1">
              Patient name
            </Typography>
            <Stack direction="row" spacing={2}>
              <Grid item sx={{ flexGrow: 1 }}>
                <CustomInputField
                  error={Boolean(
                    formik.touched.firstName && formik.errors.firstName,
                  )}
                  maxLength={80}
                  label="First Name"
                  fullWidth
                  disabled={disabled}
                  width="100%"
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    '& .MuiInputBase-input': {
                      backgroundColor: disabled && '#F1F1F1',
                    },
                  }}
                  type="text"
                  placeholder="First"
                  value={formik.values.firstName}
                />
                {Boolean(
                  formik.touched.firstName && formik.errors.firstName,
                ) && (
                  <Typography color="error" variant="body2">
                    {formik.touched.firstName && formik.errors.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item sx={{ flexGrow: 1 }}>
                <CustomInputField
                  error={Boolean(
                    formik.touched.lastName && formik.errors.lastName,
                  )}
                  maxLength={80}
                  disabled={disabled}
                  label="Last Name"
                  width="100%"
                  fullWidth
                  type="text"
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Last"
                  value={formik.values.lastName}
                  sx={{
                    '& .MuiInputBase-input': {
                      backgroundColor: disabled && '#F1F1F1',
                    },
                  }}
                />
                {Boolean(formik.touched.lastName && formik.errors.lastName) && (
                  <Typography color="error" variant="body2">
                    {formik.touched.lastName && formik.errors.lastName}
                  </Typography>
                )}
              </Grid>
            </Stack>
          </Grid>
          <Grid container direction="column">
            <Typography color={darkTextColor} variant="body1">
              Date of birth
            </Typography>
            <CustomInputField
              sx={{
                color: 'black !important',
                '& .MuiInputBase-input': {
                  backgroundColor: disabled && '#F1F1F1',
                },
              }}
              disabled={disabled}
              placeholder="mm/dd/yyyy"
              fullWidth
              width="100%"
              name="dateOfBirth"
              value={formik.values.dateOfBirth}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.dateOfBirth && formik.errors.dateOfBirth,
              )}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                handleUpdateDate(e);
              }}
            />
            {Boolean(
              formik.touched.dateOfBirth && formik.errors.dateOfBirth,
            ) && (
              <Typography color="error" variant="body2">
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth}
              </Typography>
            )}
          </Grid>
          <Grid container direction="column">
            <Typography color={darkTextColor} variant="body1">
              Sex
            </Typography>
            {disabled ? (
              <Grid container sx={{ flexGrow: 1 }} direction="row" spacing={2}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      px: 2,
                      height: '45px',
                      borderRadius: '4px',
                      background: '#F1F1F1',
                      border: '1px solid #E2E2E2',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ opacity: '0.4' }}
                      color={darkTextColor}
                    >
                      Male
                    </Typography>
                    <Radio
                      disabled
                      sx={{ p: 0, bgcolor: '#FFFFFF' }}
                      onChange={() => handleSexChange('male')}
                      checked={!isFemale}
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      px: 2,
                      height: '45px',
                      borderRadius: '4px',
                      background: '#F1F1F1',
                      border: '1px solid #E2E2E2',
                    }}
                  >
                    <Typography
                      sx={{ opacity: '0.4' }}
                      variant="body1"
                      color={darkTextColor}
                    >
                      Female
                    </Typography>
                    <Radio
                      disabled
                      sx={{ p: 0, bgcolor: '#FFFFFF' }}
                      onChange={() => handleSexChange('female')}
                      checked={isFemale}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ flexGrow: 1 }} direction="row" spacing={2}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      px: 2,
                      height: '45px',
                      borderRadius: '4px',
                      background: !isFemale
                        ? 'rgba(71, 187, 146, 0.12)'
                        : '#FFFFFF',
                      border: !isFemale
                        ? '1px solid #47BB92'
                        : '1px solid #E2E2E2',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={!isFemale ? '#47BB92' : darkTextColor}
                    >
                      Male
                    </Typography>
                    <Radio
                      sx={{ p: 0 }}
                      onChange={() => handleSexChange('male')}
                      checked={!isFemale}
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      px: 2,
                      height: '45px',
                      borderRadius: '4px',
                      background: isFemale
                        ? 'rgba(71, 187, 146, 0.12)'
                        : '#FFFFFF',
                      border: isFemale
                        ? '1px solid #47BB92'
                        : '1px solid #E2E2E2',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={isFemale ? '#47BB92' : darkTextColor}
                    >
                      Female
                    </Typography>
                    <Radio
                      sx={{ p: 0 }}
                      onChange={() => handleSexChange('female')}
                      checked={isFemale}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container direction="column">
            <Typography color={darkTextColor} variant="body1">
              Phone number
            </Typography>
            <CustomInputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              label="Phone Number"
              fullWidth
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: disabled && '#F1F1F1',
                },
              }}
              disabled={disabled}
              width="100%"
              name="phoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="(xxx) xxx-xxxx"
              value={formik.values.phoneNumber}
              inputComponent={TextMaskCustom}
            />
            {Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber,
            ) && (
              <Typography color="error" variant="body2">
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </Typography>
            )}
          </Grid>
          <Grid container direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Typography color={darkTextColor} variant="body1">
                Email address
              </Typography>
              <Typography
                color={darkTextColor}
                sx={{ opacity: 0.5 }}
                variant="body1"
              >
                Optional
              </Typography>
            </Stack>
            <CustomInputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              label="Patient Email"
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: disabled && '#F1F1F1',
                },
              }}
              maxLength={140}
              disabled={disabled}
              fullWidth
              width="100%"
              name="patientEmail"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="name@example.com"
              value={formik.values.patientEmail}
            />
            {Boolean(
              formik.touched.patientEmail && formik.errors.patientEmail,
            ) && (
              <Typography color="error" variant="body2">
                {formik.touched.patientEmail && formik.errors.patientEmail}
              </Typography>
            )}
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{ flexWrap: 'nowrap' }}
          >
            <Switch
              disabled={disabled}
              defaultChecked
              checked={formik.canForward}
              onChange={(e, value) => formik.setFieldValue('canForward', value)}
            />
            <Typography color={darkTextColor} variant="body1">
              Enable specialist to forward patient to someone more appropriate
            </Typography>
          </Stack>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default PatientDetails;
