import { Typography, Stack, Chip } from '@mui/material';
import CustomChip from '../../components/customChip';
import { NotificationNumber } from '../../components/notificationNumber';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  NotificationColors,
} from '../../constants';
const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getReferralStatus = (status) => {
  if (status === null) {
    return 'Pending';
  }
  if (status === 'accept') {
    return 'Accepted';
  }
  if (status === 'decline') {
    return 'Declined';
  }
  return CapitalizeFirstLetter(status);
};

// userChats.find((chat) => chat.user.id === selected.id)
export const PatientNameCard = ({
  referral,
  user,
  chats,
  selectedPatient,
  setSelectedPatient,
}) => {
  const unreadMessages = chats.chat?.filter(
    (chat) =>
      chat.patientId === referral.id &&
      !chat.readAt &&
      chat.senderId !== user.id,
  );
  return (
    <Stack
      sx={{
        bgcolor: referral.id === selectedPatient ? '#F7F7F7' : '#FFFFFF',
        borderLeft: referral.id === selectedPatient && '2px solid #47BB92',
        cursor: 'pointer',
      }}
      onClick={() => {
        setSelectedPatient(referral.id);
      }}
    >
      <Stack
        sx={{
          p: 2,
          borderRadius: '0px',
          px: 3,
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography
            fontWeight={500}
            color={darkTextColor}
            fontSize="12px"
            sx={{ overflowWrap: 'anywhere' }}
          >
            {referral.firstname}&nbsp;{referral.lastname}
            ,&nbsp;
            {getAge(referral?.dateOfBirth)} y.o.{' '}
          </Typography>
          <Chip
            label={getReferralStatus(referral?.status)}
            sx={{
              color:
                referral?.status === null
                  ? NotificationColors.pending.color
                  : referral?.status === 'accept'
                  ? NotificationColors.success.color
                  : referral?.status === 'decline'
                  ? NotificationColors.error.color
                  : NotificationColors.dark.color,
              backgroundColor:
                referral?.status === null
                  ? NotificationColors.pending.bgcolor
                  : referral?.status === 'accept'
                  ? NotificationColors.success.bgcolor
                  : referral?.status === 'decline'
                  ? NotificationColors.error.bgcolor
                  : NotificationColors.dark.bgcolor,
              fontWeight: 500,
              p: 0,
              m: 0,
              borderRadius: '4px',
              height: '12px',
              fontSize: '10px',
              width: 'fit-content',
            }}
          />
        </Stack>
        {unreadMessages.length ? (
          <NotificationNumber number={unreadMessages.length} />
        ) : (
          <></>
        )}
      </Stack>
      <div
        style={{
          width: '85%',
          borderRadius: '4px',
          alignSelf: 'center',
          height: '1.5px',
          backgroundColor: '#F7F7F7',
        }}
      />
    </Stack>
  );
};
